.App {
    text-align: center;
}

.clickable {
    cursor: pointer;
}

#notification-container {
    position: absolute;
    bottom: 0px;
    right: 0px;
    min-width: 20em;
    max-width: 90vw;
    max-height: 80vh;
    overflow: auto;
}

.notification-header {
    background: #2184d04b;
    font-style: italic;
}

.playground-container {
    border: 1px dotted black;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.playground-container .playground {
    position: relative;
    width: 100%;
}
